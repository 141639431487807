.logo-background {
  display: flex;
  justify-content: center;
  background: steelblue;
  border-radius: 50%;
  height: 48px;
  width: 48px;
  align-items: center;
  margin: 4px;
}
